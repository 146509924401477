<template>
  <v-container
    fluid
  >

    <!--<v-row>
      <v-col>
        <v-btn
          @click="dialogNewCourse = true"
        >
          New Course
        </v-btn>
      </v-col>
    </v-row>-->

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="sessions"
          :items-per-page="10"
          @click:row="printCoverPage"
          class="row-pointer"
        >

          <template v-slot:item.date="{ item }">
            {{ formatUtils.formatDate(item.sessiontime) }}
          </template>
 
          <!--<template v-slot:item.student="{ item }">
            {{ item.student }}
          </template>-->

        </v-data-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
  import errorUtils from '@/utils/errorUtils'
  import formatUtils from '@/utils/formatUtils'
  import { RepositoryFactory } from '@/repositories/RepositoryFactory'

  const UserRepository = RepositoryFactory.get('user');

  export default {
    name: 'CoverPages',

    //components: {
    //  courseExplorer: () => import("@/components/CourseExplorer"),
    //  lessonViewer: () => import("@/components/LessonViewer"),
    //},

    data: () => ({
      formatUtils: formatUtils,
      headers: [    
        {
          text: 'Date',
          value: 'date',
        },          
        {
          text: 'Student',
          value: 'student',
        },
        {
          text: 'Username',
          value: 'username',
        },
        {
          text: 'Email',
          value: 'email',
        },                
        {
          text: 'Course',
          value: 'course',
        },  
        {
          text: 'Lesson',
          value: 'lesson',
        },        
        {
          text: 'Result',
          value: 'result',
        },
        {
          value: 'context',
        },              
      ],      
      sessions: [],
      //locales: [],
      //publishers: [],      
      //dialogNewCourse: false,
      //title: '',   
      //publisherId: 0,   
      //localeId: 0,
    }),

    mounted() {      
      this.loadSessions();
    },

    methods: {

      async loadSessions() {
        this.scores = [];
        const { data } = await UserRepository.listSessions();
        if (data.success) {          
          this.sessions = data.data.sessions;          
        } else {
          errorUtils.showError(data.message);
        }
      },

      async printCoverPage(item) {
        window.open('/printcoverpage?userscoreid=' + item.userscoreid, '_blank');        
      },

    },

  }
</script>

<style scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }
</style>
