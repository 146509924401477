export default { 

    formatBalance(amount) {
        let result = '';

        if (amount > 0) {
            result = 'R' + Number(amount).toFixed(2) + ' Dt';
        } else if (amount == 0) {
            result = 'R' + Number(amount).toFixed(2);
        } else {
            result = 'R' + Math.abs(Number(amount)).toFixed(2) + ' Cr';
        }

        return result;
    },

    formatDate(timestamp) {
        let date = new Date(timestamp * 1000);
        const day = date.toLocaleString('default', { day: 'numeric' });
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        return day + ' ' + month + ' ' + year;        
    }

};
